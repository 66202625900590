<template>
  <section>
    <div v-show="showDetails">
      <img class="pdf-icon" src="@/assets/images/pdf.png" alt="">
    </div>
  </section>
</template>

<script>
export default {
  name: 'DetailFolder',
  props: {
    showDetails: Boolean
  },
  data: () => ({

  })
}
</script>

<style scoped>
.pdf-icon{
  width: 8rem !important;
}
</style>