<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Febrero 2024                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section>
    <div v-if="!showDetails">
      <section class="d-flex ma-3">
        <v-autocomplete v-model="estado" :items="['Activo', 'Inactivo']" label="Estado" class="me-4" dense outlined>
        </v-autocomplete>
        <v-text-field v-model="numDocumento" :counter="11" label="N° de documento" dense outlined>
        </v-text-field>

        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="fechaDesde" label="Fecha desde" class="mx-3" readonly v-bind="attrs" v-on="on" dense
              outlined>
            </v-text-field>
          </template>
          <v-date-picker v-model="fechaDesde" :active-picker.sync="activePicker"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="saveDate"></v-date-picker>
        </v-menu>

        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="fechaHasta" label="Fecha Hasta" class="me-6" readonly v-bind="attrs" v-on="on" dense
              outlined>
            </v-text-field>
          </template>
          <v-date-picker v-model="fechaHasta" :active-picker.sync="activePicker"
            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="saveDate"></v-date-picker>
        </v-menu>
      </section>

      <section class="d-flex flex-wrap">
        <div class="folder-container" @click="detailsFolder()">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">1</span>
          <v-icon class="check-icon">check_circle</v-icon>
        </div>

        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">2</span>
          <!-- <v-icon class="check-icon">check_circle</v-icon> -->
        </div>
        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">1</span>
          <v-icon class="check-icon">check_circle</v-icon>
        </div>

        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">2</span>
          <!-- <v-icon class="check-icon">check_circle</v-icon> -->
        </div>
        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">1</span>
          <v-icon class="check-icon">check_circle</v-icon>
        </div>

        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">2</span>
          <!-- <v-icon class="check-icon">check_circle</v-icon> -->
        </div>
        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">1</span>
          <v-icon class="check-icon">check_circle</v-icon>
        </div>

        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">2</span>
          <!-- <v-icon class="check-icon">check_circle</v-icon> -->
        </div>
        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">1</span>
          <v-icon class="check-icon">check_circle</v-icon>
        </div>

        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">2</span>
          <!-- <v-icon class="check-icon">check_circle</v-icon> -->
        </div>
        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">1</span>
          <v-icon class="check-icon">check_circle</v-icon>
        </div>


        <div class="folder-container">
          <img class="folder-icon" :src="folderImg">
          <span class="folder-counter">2</span>
          <!-- <v-icon class="check-icon">check_circle</v-icon> -->
        </div>

      </section>


    </div>
    <DetailFolder :showDetails="showDetails" />
  </section>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import folder from '@/assets/images/folder.png';
import DetailFolder from '../../../../components/DetailFolder.vue';

export default {
  components: { DetailFolder },
  name: 'BandejaExpedientes',
  data: () => ({
    showDetails: false,
    folderImg: folder,
    activePicker: null,
    estado: '',
    numDocumento: '',
    menu: false,
    fechaDesde: '',
    fechaHasta: '',

  }),
  watch: {
    menu(val) { val && setTimeout(() => (this.activePicker = 'YEAR')) },

  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    detailsFolder() {
      this.showDetails = true;
      console.log('egs');
    }
  }
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de styles                                                      ###### -->
<!-- #################################################################################### -->
<style scoped>
.folder-container {
  position: relative;
  padding: 10px;
}

.folder-icon {
  color: rgb(167, 163, 163);
  cursor: pointer;
  width: 8rem;
  transition: transform 0.3s ease, filter 0.5s ease;
  /* Agrupa ambas transiciones en una sola propiedad */
}

.check-icon {
  position: absolute;
  top: 70%;
  left: 83%;
  color: green;
  transform: translate(-50%, -50%);
}

.folder-icon:hover {
  transform: translate(-0%, -3%);
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.3));
}

.folder-container:hover .check-icon {
  transform: translate(-50%, -60%);
}

.folder-container:hover .folder-counter {
  transform: translate(-0%, -20%);
}

.folder-counter {
  position: absolute;
  top: 15%;
  left: 15%;
  font-size: 1rem;
  color: black;
  transition: transform 0.3s ease;
}
</style>